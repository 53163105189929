import React, { useEffect, useState } from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import OneTwoThreePage from '../../components/Games/Trivia/OneTwoThree/OneTwoThree'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import {
  GAMES,
  TRIVIA,
  ONE23 as ONE23_LINK,
} from '../../components/internal-links'
import { ONE23 } from '../../components/download-links'
import {
  generateBreadcrumbsSchema,
  generateFaqSchema,
  generateOrgSchema,
  generatePokerReviewSchemaAndroid,
  generatePokerReviewSchemaIos,
  generateWebsiteSchema,
} from '../../lib/generate-schema'
import { Faq } from '../../components/FAQs/faqs-list-interface'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Games',
    url: GAMES,
  },
  {
    title: 'Trivia',
    url: TRIVIA,
  },
  {
    title: '123 (Maths Quiz)',
    url: ONE23_LINK,
  },
]

const OneTwoThree: React.FC = () => {
  const [one23FaqsSt, setOne23FaqsSt] = useState<Faq[]>([])

  useEffect(() => {
    import('../../components/FAQs/faqs-list').then(({ one23Faqs }) =>
      setOne23FaqsSt(one23Faqs)
    )
  }, [])

  return (
    <Layout showBreadcrumbs breadcrumbs={breadcrumbs} apkLink={ONE23}>
      <SEO
        title="Play Maths Quiz Online To Earn Money | Play & Win 1,00,000+ on Mega"
        description="Solve maths quizzes to win real money 24/7, & withdraw instantly to your paytm or bank. Download Mega to play 123 Trivia puzzles & beat other players to the clock."
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
        reviewSchemaPokerAndroid={generatePokerReviewSchemaAndroid()}
        reviewSchemaPokerIos={generatePokerReviewSchemaIos()}
        faqSchema={generateFaqSchema(one23FaqsSt)}
      />
      <OneTwoThreePage />
    </Layout>
  )
}

export default OneTwoThree
