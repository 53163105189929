import React from 'react'
import './CategoryInfo.scss'
import ReadMoreLessSections from './ReadMoreLessSections'

const OneTwoThreeInfo: React.FC = () => {
  const visible = (
    <>
      <h2 className="page-title">Learn more about 123</h2>
      <p>
        Got confused with BODMAS &amp; PEMDAS as a child? Remember all those
        hours spent over multiplication tables? Thinking what to do with all
        these mathematics skills now? What if you could use your superpowers to
        play some math quizzes and win real money prizes — all with a few right
        taps on your phone!
      </p>
      <p>
        Discover an exciting multiplayer maths game of skill and smarts, only on
        Mega. Can you solve sums speedily? Can you beat the other players to the
        clock, with your strong maths and sharp reflexes?
      </p>
      <p>
        Play 123 on Mega, India’s hottest real money gaming app. Bring your
        skills to the table, beat your opponents in maths quizzes and earn real
        money rewards. 24x7.
      </p>
    </>
  )
  const hidden = (
    <>
      <p>
        Mega 123 is played against real players in real time. You compete with
        2-3 players at a time, to solve basic math problems the fastest -
        Addition, Subtraction, Multiplication, Division, Sequences, Prime
        Numbers.
      </p>
      <p>
        The questions are generated through specially designed systems that
        create unique sets of questions. This ensures 123 truly remains a game
        of skill. Exercise your math skills and sharpen your reflexes. Solve and
        tap the right answer the fastest. Beat your opponents to win math
        quizzes to earn real money rewards.
      </p>

      <h3>How to Play</h3>
      <ul>
        <li>
          <p>Download Mega. Choose 123 under Trivia.</p>
        </li>
        <li>
          <p>
            Pick any table. The buy-in amounts start from as low as ₹1, so pick
            a table as per your comfort level. All games are played between 2-3
            players in real-time.
          </p>
        </li>
        <li>
          <p>
            Solve fast, solve right. Beat your opponents, take the prize. You
            can play any number of rounds on a table.
          </p>
        </li>
        <li>
          <p>
            The winnings are credited to your wallet as soon as you leave the
            table. Withdraw it easily.
          </p>
        </li>
        <li>
          <p>
            Spread the joy. Go tell your friends how you defeated players in an
            online maths quiz to earn some real money rewards!
          </p>
        </li>
      </ul>

      <h3>Rules</h3>
      <ul>
        <li>
          <p>All players start with an equal number of points.</p>
        </li>
        <li>
          <p>
            To win, a player must ‘race to 0’ — reduce points to reach 0 the
            fastest.
          </p>
        </li>
        <li>
          <p>
            Each math problem has 4 answers. Tap the right answer faster than
            your opponents, to reduce a point. The time taken by the players to
            solve the problem decides whose point gets reduced.
          </p>
        </li>
        <li>
          <p>
            The round is completed whenever any player answers enough questions
            to reach 0. The final score of all the remaining players depends on
            their points left.
          </p>
        </li>
        <li>
          <p>
            The total points left multiplied by the point rate, becomes the
            total winnings of the winner (after removing the rake).
          </p>
        </li>
      </ul>

      <h3>Multiplayer games. Round the clock.</h3>
      <p>
        123 is played in a multiplayer format, with 100% verified profiles and
        only a limited number of players on each table. The tables run 24x7, so
        you don’t have to wait to play your favourite math quiz to win real
        money prizes.
      </p>

      <h3>Smart questions. True skills.</h3>
      <p>
        The questions and multiple choice options in Mega 123 are generated
        through specially designed systems, so that there is no repetition of
        any sets of questions. This means it remains fair to all the players,
        and is truly a game of skill, and an exciting way to play an online math
        quiz to earn real money rewards.
      </p>

      <h3>Instant Wins. Instant Withdrawals.</h3>
      <p>
        You don’t have to wait hours or even minutes to finish the game, or wait
        for some random leaderboard rankings to win money in a maths quiz. In
        Mega 123, the total amount won is transferred to the winnings wallet as
        soon as you leave the table. The winnings can be easily transferred to
        your Paytm wallet, through UPI or directly to your bank account, all
        within a minute. It’s simply about speed, right?
      </p>
    </>
  )
  return (
    <div className="category-info">
      <ReadMoreLessSections visible={visible} hidden={hidden} gameName="123" />
    </div>
  )
}

export default OneTwoThreeInfo
